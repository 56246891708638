import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu',
    pathMatch: 'full'
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'route-tourist/:id',
    loadChildren: () => import('./route-tourist/route-tourist.module').then( m => m.RouteTouristPageModule)
  },
  {
    path: 'route-tourist-info',
    loadChildren: () => import('./route-tourist-info/route-tourist-info.module').then( m => m.RouteTouristInfoPageModule)
  },
  {
    path: '**', pathMatch: 'full',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true } )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
